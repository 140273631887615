<script setup lang="ts">
import { marked } from 'marked'

const props = defineProps<{
	data: {
		content: string,
		isHtml: false,
	},
}>()
let div = ref<HTMLElement>()

function loadScript (attributes: { src: string, id?}, target = document.body) {
	// if already existing or no src on script
	if (target.querySelector(`script[src='${attributes.src}']`) || !attributes.src) return
	let script = document.createElement('script') as HTMLScriptElement
	// make sure element has an id
	if (!attributes.id) attributes.id = attributes.src
	script.defer = true
	// append all script attributes back onto the script
	Object.keys(attributes).forEach(attributeKey => {
		script.setAttribute(attributeKey, attributes[attributeKey])
	})
	target.appendChild(script)
}

const linkRenderer = (href: string, _title: string, text: string): string => {
	return `<a href="${href}" target="_blank">${text}</a>`
}

const parseContent = () => {
	if (!props.data?.content) return
	if (props.data?.isHtml) {
		// HTML
		const parser = new DOMParser()
		const virtualDOM = parser.parseFromString(props.data.content, 'text/html')
		let scriptNodes = [...virtualDOM.querySelectorAll('script')]
		let scriptsWithAttributes = []
		scriptNodes.forEach((node, index) => {
			for (const attribute of node.attributes) {
				if (!scriptsWithAttributes[index]) scriptsWithAttributes[index] = {}
				scriptsWithAttributes[index][attribute.name] = attribute.value
			}
		})
		scriptNodes.forEach(a => a.remove())
		div.value.innerHTML = virtualDOM.body.innerHTML
		scriptsWithAttributes.forEach(
			// for now we attach the script to the text block .value itself
			// as we assume thats more default -ish then wanting to rewrite the whole page
			// and add global javascript to the page
			scriptWithAttributes => loadScript(scriptWithAttributes, div.value),
		)
	} else if (div.value) {
		// Markdown
		const renderer = { link: linkRenderer }
		marked.use({ renderer })
		div.value.innerHTML = marked.parse(props.data.content)
	}
}
onMounted(parseContent)
</script>

<template>
	<div
		ref="div"
		class="text-body-1 leading-tight font-hind text-primary-600"
	/>
</template>

<style scoped>
:deep(h1) {
	@apply text-h1 mb-4 font-bold;
}
:deep(h2) {
	@apply text-h2 mb-4 font-bold;
}
:deep(h3) {
	@apply text-h3 mb-4 font-bold;
}
:deep(h4) {
	@apply text-h4 mb-4 font-bold;
}
:deep(a) {
	@apply underline;
}
:deep(a:hover) {
	@apply no-underline;
}
:deep(p) {
	@apply mb-4;
}
:deep(ul),
:deep(ol) {
	@apply list-inside mb-4;
}
:deep(ul) {
	@apply list-disc;
}
:deep(ol) {
	@apply list-decimal;
}
:deep(table) {
	@apply my-6 mx-0 w-full table-auto border-collapse overflow-hidden
}
:deep(th) {
	@apply relative min-w-[1rem] border p-[0.35rem] text-left font-bold bg-mono-200
}
:deep(td) {
	@apply relative min-w-[1rem] border p-[0.35rem] text-left font-normal
}
</style>
