<script setup lang="ts">
const runtimeConfig = useRuntimeConfig()
defineProps<{
	data: {
		formId: string,
	},
}>()

const accessTokenCookieName = runtimeConfig.public.ATCookieName
const clientId = runtimeConfig.public.clientId
</script>
<template>
	<incrowd-forms
		:id="data.formId"
		:client-id="clientId"
		:access-token-cookie-name="accessTokenCookieName"
	/>
</template>
